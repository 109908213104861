/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */
import React from 'react'
import Lightbox from 'react-image-lightbox'

import GalleryCard from './GalleryCard'
import './PostSection.css'

class GallerySection extends React.Component {
  static defaultProps = {
    posts: [],
    title: '',
    limit: 12,

    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 12
  }

  state = {
    limit: this.props.limit,
    images: null,
    photoIndex: 0
  }

  increaseLimit = () =>
    this.setState((prevState) => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  openGallery = (collection) => {
    const imgs = collection.map((i) => {
      return i.imageUrl
    })
    this.setState({ images: imgs })
  }

  render() {
    const { posts, title, showLoadMore, loadMoreTitle } = this.props
    const { limit, photoIndex, images } = this.state
    const visiblePosts = posts.slice(0, limit || posts.length)
    return (
      <div className="PostSection">
        {title && <h2 className="PostSection--Title">{title}</h2>}
        {!!visiblePosts.length && (
          <div className="PostSection--Grid">
            {visiblePosts.map((post, index) => (
              <div
                onClick={() => {
                  this.openGallery(post.imageCollection)
                }}
              >
                <GalleryCard key={post.id + index} {...post} />
              </div>
            ))}
          </div>
        )}
        {showLoadMore && visiblePosts.length < posts.length && (
          <div className="taCenter">
            <button className="button" onClick={this.increaseLimit}>
              {loadMoreTitle}
            </button>
          </div>
        )}
        {images && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() =>
              this.setState({ images: null, photoIndex: 0 })
            }
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </div>
    )
  }
}

export default GallerySection
