/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import React from 'react'
import dayjs from 'dayjs'

import Image from './Image'
import './PostCard.css'

const GalleryCard = ({
  featuredImage,
  title,
  body,
  description = null,
  eventDate,
  categories = [],
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  return (
    <div
      style={{ cursor: 'pointer', backgroundColor: 'white', color: 'black' }}
    >
      {eventDate && (
        <div
          style={{
            padding: 4,
            marginLeft: 8,
            color: '#606a2b'
          }}
        >
          {dayjs(eventDate).format('dddd, MMMM D YYYY')}
        </div>
      )}
      {featuredImage && (
        <div className="PostCard--Image2 relative">
          <Image background src={featuredImage} alt={title} />
        </div>
      )}
      {!description && (
        <div className="PostCard--Content">
          {title && <h3 className="PostCard--Title">{title}</h3>}
          <div className="PostCard--Category">
            {categories && categories.map((cat) => cat.category).join(', ')}
          </div>

          <section className="PostCard--Excerpt">
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </section>
        </div>
      )}
      {description && (
        <h3 style={{ marginTop: -8, textAlign: 'center' }}>{description}</h3>
      )}
    </div>
  )
}

export default GalleryCard
